.pagination {
  justify-content: center;
  li {
    margin: 15px 5px;
  }
  a {
    border-radius: 4px;
    border: 1px solid #CCDFE5;
    padding: 16px 25px;
    cursor: pointer;
    color: #000;
    background-color: #fff;
    transition: all .2s ease-in-out;

    &:hover {
      background-color: #00B8F5;
      color: #fff !important;
    }

    @media (max-width: 585px) {
      padding: 10px 18px;
    }
  }

  .active a {
    background-color: #00B8F5;
    color: #fff;

    &:hover {
      background-color: #00B8F5;
      color: #fff;
    }
  }

}

