.inputElement {
  //width: 30px;
  background: #FFFFFF;
  border: 1px solid #CCDFE5;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 25px;
  height: 40px;
  padding: 5px 5px 5px 10px;
  font-family: Quicksand;
  font-weight: 500;
  transition: margin .2s linear;

  @media (max-width: 992px) {
    width: 100%;
  }

  @media (max-width: 585px) {
    border-radius: 10px;
  }
}

.inputRadio {
  width: 25px;
  margin-right: 10px;
}

.inputLabel {
  width: 250px;
  position: relative;
  top: 8px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;

}

.btn-purple-checkForm {
  position: relative;
  left: 250px;
  transform: translateX(-18px);

  @media (max-width: 992px) {
    transform: none;
    left: unset;
    margin: auto;
    display: block;
    margin-top: 25px;
  }
}

.inputWrapper {
  position: relative;
  @media (max-width: 992px) {
    display: block !important;
  }
}

.InputElement:focus {
  outline: none !important;
  background-color: #ccc;
  border: 2px solid #00B8F5 !important;
}

input:focus,
input:active{
  outline: none !important;
  border: 2px solid #99dbf6 !important;
}

.input {
  width: 100%;
  position: relative;
}

.error {
  display: none;
  position: absolute;
  left: 5px;
  top: 50px;
  color: red;
  height: 50px;
}

.error-input {
  border: 1px solid red;
}

.error-text {
  color: red;
}

.error-show {
  display: block;
}

.Invalid {
  border: 1px solid red;
  background-color: #FDA49A;
  margin-bottom: 50px;
}


.error-label {
  color: red;
  font-family: 'Mulish';
  margin-top: 10px;
}

.customInput {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #CCDFE5 !important;
  transition: all .2s linear;
  position: relative;
  padding-left: 35px;


  &-group {
    position: relative;
    max-width: 75%;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 585px) {
      max-width: 90%;
    }
  }

  &-icon {
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 10;
    transform: translateY(-50%);
  }

  &:focus {
    outline: none !important;
    border: none !important;
    border-bottom: 1px solid #B800F5 !important;

    box-shadow: none !important;
  }

  &::placeholder {
  }
}

.FormWrap-buttons__group {
  @media (max-width: 585px) {
    justify-content: center !important;
    flex-direction: column;
    align-items: center;
  }
}