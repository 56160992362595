.LimitedLoan-root {
  border: 1px solid #CCDFE5;
  border-radius: 10px;
  background: linear-gradient(90deg, #00B8F5 0%, #B800F5 100%);
  padding: 15px;
  transition: all .2s linear;
  width: 100%;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;

  .LimitedLoan__wrap {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 2;

    @media (max-width: 585px) {
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;

    }

  }

  .LimitedLoan__background {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 10%;
    opacity: 0.7;
  }

  .LimitedLoan__icon-wrap {
    display: flex;

    img {
      margin-right: 10px;
    }

    @media (max-width: 585px) {
      flex-direction: column;

      img {
        height: 50px;
      }
    }

    @media (max-width: 480px) {
      margin-bottom: 15px;
      justify-content: center;
    }

    //@media (max-width: 320px) {
    //  display: none;
    //}
  }

  .LimitedLoan__paragraph {
    color: #fff;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    position: relative;
    top: 5px;
  }

  .btn-purple {
    min-width: 30%;
    margin: 0;

    @media (max-width: 1380px) {
      font-size: 13px;
    }

    @media (max-width: 992px) {
      top: 0;
    }
  }
}

.limitedOfferCard {
  background: #FFFFFF;
  box-shadow: 0px 25px 60px rgba(0, 84, 112, 0.15);
  border-radius: 10px;
  max-width: 80%;
  margin: 35px auto 25px auto;
  //padding-left: 15px;
}

.limitedOfferImage {
  position: absolute;
  bottom: 0;
  right: 20px;
  max-height: 180px;
}