.PersonalInfo-root {




  .row-inputs {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 580px) {
      flex-direction: column;
    }
  }

  .input-column {
    max-width: 49%;
    width: 100%;

    @media (max-width: 580px) {
      max-width: 100%;
    }
  }


  .input-disabled {
    pointer-events: none;

    /* for "disabled" effect */
    //opacity: 0.4;
    background: rgba(236, 236, 236, .4);
  }

  .input-wrap {
    background: #fff;
    padding: 0;
    position: relative;

    margin: 0 4px 12px 0;


    .labelText {
      position: absolute;
      top: 12px;
      left: 10px;
      font-family: Mulish;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;

      /* šedá */

      color: #5A7984;

      @media (max-width: 580px) {
        top: 8px;
        font-size: 11px;
      }
    }
  }

  .inputs-wrap {
    width: 100%;
    //padding: 5px 10px;

    .inputElement {
      width: 100%;
      height: 70px;
      padding-top: 25px;
      margin: 0;

      @media (max-width: 580px) {
          height: 55px;
        font-size: 16px;
      }
    }


  }
  .Question-listItem {

    padding: 0;

    &:before {
      height: 25px;
      width: 25px;
      top: 5px;
    }

    &:after {
      height: 17px;
      width: 17px;
      left: 4px;
      top: 12px;
    }

  }

}

.PersonalInfo,
.PersonalContact,
.GDPR{
  max-width: 60%;

  @media (max-width: 1480px) {
    max-width: 80%;
  }

  @media (max-width: 992px) {
    max-width: 100%;
  }
}

.GDPR {
  margin: 15px 0;

  input[type="checkbox"] {
    transform: scale(1.5);
    flex: 0 1 10%
  }

  &:before {
    display: none !important;

  }

  input {
    width: 15%;
    height: 30px;
    margin-right: 15px;
    cursor: pointer;



    &:hover {
      &:before {
        display: none !important;
      }
    }
  }
  p {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */


    /* šedá */

    color: #5A7984;

    @media (max-width: 580px) {
      font-size: 12px;
    }

  }
}
