.SignContract {
  width: inherit;

  &--number {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #CCDFE5;
    text-align: center;
    border: 1px solid #CCDFE5 ;
    padding: 11px 19px;
  }

  &--text {
    color: #CCDFE5;
    max-width: 75%;
    text-align: center;
    margin: 10px auto 0 auto;
  }

  &--number__active {
    color: #B800F5;
    border: 1px solid #B800F5 ;
  }

  &__active {
    .SignContract--text {
      color: #B800F5 !important;
    }

    .SignContract--number {
      color: #B800F5 !important;
      border: 1px solid #B800F5 !important;
    }
  }

}
  .SignContract__signed{
    .SignContract--text {
      color: #27AE60 !important;
    }

    .SignContract--number {
      color: #27AE60 !important;
      border: 2px solid #27AE60 !important;
      background: #27AE60 !important;
    }

    img {
      position: relative;
      left: -4px;
      top: -2.5px;
    }
  }
