.PersonalId-root {
  transition: all .2s linear;
  padding-left: 15px;
  .ID__card,
  .BankStatement__card {
    border: 1px solid #CCDFE5;
    border-radius: 10px;
    background: #fff;
    padding: 20px;
    transition: all .2s linear;
    margin-bottom: 15px;
    position: relative;
    z-index: 4;
  }

  .BankStatement__card {
    height: fit-content;
    transition: all .2s linear;

    .bankAccount__bg {
      position: absolute;
      bottom: 0;
      right: 15px;
      height: 135px;
      max-height: 100px;
      transition: all .2s linear;
    }
  }

  .id-list-wrap {
    transition: all .2s linear;
    .id-list-header {
      font-family: Mulish;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #5A7984;
      margin-top: 20px;

      p {
        margin-bottom: 0;

        @media (max-width: 1480px) {
          font-size: 14px;
        }
      }
    }

    .id-list-body {

      .id-list {
        padding-left: 0;
        list-style: none;
      }

      .id-list-item {
        display: flex;
        justify-content: space-between;
        padding: 10px 0 15px 0;
        border-bottom: 1px solid #CCDFE5;
        margin-bottom: 10px;
      }

      .id-name-wrap {
        position: relative;
        top: 10px;
        padding-left: 20px;
      }

      .id-file-icon {
        position: absolute;
        top: 0;
        left: -5px;
        height: 22px;
        width: 22px;
      }

      .id-file-name {
        font-family: Mulish;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height */

        text-decoration-line: underline;

        /* modrá */

        color: #00B8F5;

        @media (max-width: 1480px) {
          font-size: 14px !important;
        }

        @media (max-width: 585px) {
          font-size: 12px;
        }

      }

      .id-status-success {
        background: #E8F4E6;
        border-radius: 4px;
        display: inline-block;
        padding: 12px 14px;
        font-family: Mulish;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        text-align: right;
        color: #27AE60;

        @media (max-width: 1480px) {
          padding: 8px 12px;

        }

        @media (max-width: 585px) {
          width: min-content;
        }
      }

      .id-status-waiting {
        background: #FFF6E9;
        border-radius: 4px;
        display: inline-block;
        padding: 12px 14px;
        color: #F2994A;
        font-family: Mulish;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        text-align: right;

        /* Orange */

        color: #F2994A;
      }
    }

    .add-another-file {
      margin-top: 10px;
      cursor: pointer;

      img {
        position: relative;
        top: 8px;
      }

      p {
        font-family: Mulish;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 32px;
        text-decoration-line: underline;
        color: #00B8F5;
        position: relative;
        top: 8px;
        margin-left: 10px;
        margin-bottom: 0;
      }
    }
  }
  .upload-wrap {
    transition: all .2s linear;
    margin-top: 30px;
  }

  .uploaded-file-wrap {
    background: #FFFFFF;
    border: 1px solid #CCDFE5;
    border-radius: 4px;
    max-width: 80%;
    width: 100%;
    display: flex;
    margin-right: 20px;
    justify-content: space-between;
    padding: 15px;

    .uploaded-label {
      font-family: Mulish;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      color: #5A7984;
      margin-bottom: 10px;
    }

    .uploaded-file-name {
      font-family: Mulish;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      margin: 0;
    }

    .uploaded-icon-success {
      width: 32px;
      height: 26px;
      position: relative;
      top: 10px;
      right: -2px;
      cursor: pointer;
    }

  }

  .upload-icon-text {
    @media (max-width: 585px) {
      font-size: 13px;
    }
  }

  .uploaded-icon-delete {
    cursor: pointer;
    width: 32px;
    height: 32px;
    position: relative;
    top: 20px;
    right: -2px;
  }

  .upload-file {
    background: #FFFFFF;
    border: 1px solid #CCDFE5;
    border-radius: 4px;
    display: inline-block;
    margin-right: 20px;
    justify-content: space-between;
    padding: 15px;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    }

    @media (max-width: 1480px) {
      p {
        font-size: 13px;
      }

      img {
        height: 20px;
      }
    }

    @media (max-width: 585px) {
      img {
        height: 20px;
      }
    }
  }


  .btn-purple {
    margin: 0;
    left: -14px;
    position: relative;
  }
}

.user_id_wrap {

  a {
    position: relative;
    color: black;

    .showTextOnHover {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -10;
    }

    &:hover {
      img {
        opacity: .7;
        transform: scale(1.02);
      }

      .showTextOnHover {
        opacity: 1;
        visibility: visible;
        height: unset;
        z-index: 2;
        transform: translate(-50%, -50%);
      }
    }
  }

  img {
    height: 100%;
    width: auto;
    max-height: 150px;
    max-width: 250px;
    transition: all 0.1s linear;

  }
}