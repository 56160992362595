.DrawerToggler {
  width: 40px;
  height: 52px;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  z-index: 110;


  div {
    width: 90%;
    position: relative;
    height: 3px;
    background: #00B8F5;
    transition: all .15s linear;
  }


  @media (min-width: 992px) {
    display: none;
  }
}

.drawerToggler-modified {
  top: -5px;
  width: 30px;
  height: 42px;
}


.DrawerToggler.open {

  div:nth-child(1) {
    top: 16px;
    width: 0;
    opacity: 0;

  }

  div:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  div:nth-child(3) {
    top: -11px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);

    @media (max-width: 580px) {
      top: -8px;
    }
  }

  //div:nth-child(4) {
  //  top: 18px;
  //  width: 0%;
  //  left: 50%;
  //}
}

.DrawerTogglerDetail {
  width: 30px;
  height: 42px;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  z-index: 110;


  div {
    width: 90%;
    position: relative;
    height: 3px;
    background: #00B8F5;
    transition: all .15s linear;
  }


  //@media (min-width: 992px) {
  //  display: none;
  //}
}


.DrawerTogglerDetail.open {


  div:nth-child(1) {
    top: 16px;
    width: 0;
    opacity: 0;

  }

  div:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  div:nth-child(3) {
    top: -8px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  //div:nth-child(4) {
  //  top: 18px;
  //  width: 0%;
  //  left: 50%;
  //}
}