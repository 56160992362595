.ClientLayout {
  //min-height: 100vh;
  height: 100%;
  position: relative;
  //overflow-x: hidden;
  margin-bottom: 30px;


  @media (max-width: 585px) {
    overflow-x: hidden;

  }

  &__background-pattern {
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh; // (90%) before
    max-height: 900px; // before this wasn't there
    z-index: 0;

    img {
      height: 100%;
    }
  }

  .ClientContent {
    height: 100%;
    position: relative;
    z-index: 1;
  }
}



main {
  //overflow-x: hidden;
  //@media (max-width: 992px) {
  //  overflow-x: unset;
  //}
  position: relative;
  z-index: 5;

  @media (min-width: 1440px) {
    min-height: 85vh;
  }

  @media (max-width: 1439px) {
    min-height: unset;
    height: 100%;
  }

}

.SideNav {
  width: 310px;
  margin-right: 25px;
  display: block;

  @media (max-width: 1280px) {
    margin-right: 10px;
  }
}

.ContactUnderNav {

}

.main-content {
  width: 100%;

  @media (max-width: 580px) {
    padding-bottom: 130px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.mobileSettings {
  @media (max-width: 585px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: 480px) {
    margin-top: -15px;
  }
}

.mobile-header {
  background: rgba(255, 255, 255, .91);
  box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-bottom: 15px !important;
  margin-bottom: 25px;
}

.DesktopOnly {
  @media (max-width: 992px) {
    display: none;
  }
}

.Open{
  //width: 100% !important;
  //height: auto;
  //visibility: visible;
  transform: translateX(0);
}

.Close {
  transform: translateX(-2020px);
  //visibility: hidden;
  //animation: fadeInRightClose .3s ease;
}

//.page__title {
//  font-family: Quicksand;
//  font-style: normal;
//  font-weight: 600;
//  font-size: 36px;
//  line-height: 45px;
//  color: #000000;
//  margin-bottom: 15px;
//
//  @media (max-width: 480px) {
//    text-align: center;
//    font-size: 32px;
//  }
//}
//
//.page__title {
//  font-family: Quicksand;
//  font-style: normal;
//  font-weight: 600;
//  font-size: 36px;
//  line-height: 45px;
//  color: #000000;
//  margin-bottom: 15px;
//
//  @media (max-width: 480px) {
//    text-align: center;
//    font-size: 32px;
//  }
//}

.page__section-title {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  /* identical to box height */


  color: #000000;

  @media (max-width: 1480px) {
    font-size: 22px !important;
  }

  @media (max-width: 580px) {
    font-size: 18px;
    line-height: 26px;
  }
}

.font-size-20 {
  font-size: 20px;
}


@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(-1020px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRightClose {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-1020px);
  }
}

.bg-unlogged {
  margin-bottom: 0 !important;
  background: radial-gradient(116.48% 209.06% at 80.47% 81.35%, #DFF7FF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
}
.bg-logged {
  background: #FAFCFD;
}