
.MuiSlider-rail,
.MuiSlider-track {
  height: 5px !important;
  border-radius: 15px !important;
}

.MuiSlider-rail {
  background-color: #D5EAF1 !important;
  opacity: 1 !important;
}

.chooseDays {

  .MuiSlider-rail::after {
    content: "";
    position: absolute;
    height: 5px;
    width: 50%;
    right: 0;
    background-color: #FDC93A;
    transition: all .2s linear;
    opacity: 1;
    //z-index: 11;

  }

  span[data-index="4"],
  span[data-index="5"],
  span[data-index="6"],
  span[data-index="7"],
  span[data-index="8"] {
    background-color: #FDC93A !important;
  }
}

//.MuiSlider-root:hover {
//  .MuiSlider-rail::after {
//    //display: block;
//    opacity: 1;
//  }
//}
.MuiSlider-track {
  background: linear-gradient(90deg, #B800F5 0%, #7D00A6 100%) !important;
  opacity: .8 !important;
}

.MuiSlider-mark {
  height: 20px !important;
  top: 5px !important;
  background-color: #C3DCE4 !important;

  //@media (max-width: 1366px) {
  //  top: 12px !important;
  //
  //}

  @media (max-width: 380px) {
    top: 12px !important;

  }
}

.MuiSlider-thumb::after  {
  width: 48px;
  height: 48px;
  display: flex;
  transform: rotate(-225deg) !important;
  align-items: center;
  border-radius: 50% 50% 50% 0 !important;
  justify-content: center;
  background: linear-gradient(90deg, #B800F5 0%, #7D00A6 100%) !important;

  @media (max-width: 580px) {
    //top: 20px !important;
    height: 32px;
    width: 32px;
    bottom: 0 !important;
    top: -10px !important;
    left: -6px !important;

  }
}

.MuiSlider-thumb::before {
  content: "";
  background: url("../../../icons/markersPoints.png") no-repeat;
  background-size: contain;
  height: 20px;
  left: 2px;
  top: -2px;
  width: 15px;
  display: block;
  position: absolute;
  z-index: 10;

  @media (max-width: 580px) {
    //top: 20px !important;
    height: 16px;
    width: 16px;
    left: 4px;
    top: -3px;
  }
}

.MuiSlider-markActive {
  background: linear-gradient(90deg, #B800F5 0%, #7D00A6 100%) !important;
}

#input-slider {
  color: #A4C9D6 !important;
  font-weight: 600;
  font-family: Quicksand;
  margin-top: 10px;
}

.MuiSlider-root {

}

.calcSummary {
  padding: 25px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 84, 112, 0.09);
  border-radius: 10px;
  margin-left: 50px;

  &-blue {
    font-family: Quicksand;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height */


    /* modrá */

    color: #00B8F5;
  }

  &-list {
    //margin-top: 20px;
    padding: 0;
    list-style: none;

  }

  &-listItem {
    padding: 15px 0;
    border-bottom: 1px solid rgba(#CCDFE5, .8);
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    /* identical to box height */


    /* šedá */

    color: #5A7984;

    &:first-child {
      border-top: 1px solid rgba(#CCDFE5, .8);
    }

    &::marker {
      content: "";
      font-size: 0;
    }

    .text-bold {
      font-weight: bold;
      font-size: 15px;
      text-align: right;
      display: block;
    }
  }

  @media (max-width: 1350px) {
    margin-left: 0;
  }

  @media (max-width: 992px) {
    margin-left: 0;
    //margin-top: 50px;
  }

  @media (max-width: 340px) {
    margin-top: 75px;
  }
}

