.mobile-footer-nav {
  position:fixed;
  bottom:0;
  left:0;
  right:0;
  z-index:1000;
  //give nav it's own compsite layer
  will-change:transform;
  transform: translateZ(0);

  display:flex;

  height:93px;

  box-shadow: 0 -2px 10px -2px rgba(0, 0, 0, .18);
  background-color:#fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.new-loan-icon {
  margin-top: -18px;

  svg {
    position: relative;
    top: -10px;
    height: 50px;
    width: 50px;
  }
}

.tabTitle {
  font-size: 11px;
  color: #00b8f5;

  @media (max-width: 325px) {
    font-size: 10px;
  }
}

.text-gray {
  color: #6c757d !important;
}

.tabIcon {
    height: 32px
}

.afternav {
  height: 25px;
  background-color: white;
  width: 100%;
}