.SideDrawer {
  background: #fff;
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: 0px 0 32px 0;
  z-index: 10;
  position: fixed;
  left: 0;
  top: 0;
  transition: transform 0.4s ease-out;
  overflow-y: scroll;
  padding-bottom: 80px;

  @media (max-width: 580px) {
    //position: absolute;
  }

  .Logo {
    padding-left: 7px;
  }

}

.unLogged {
  //display: none;
  @media (max-width: 992px) {
    background: #fff;
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 72px 0 32px 0;
    z-index: 10;
    position: fixed;
    left: 0;
    top: 0;
    transition: transform 0.4s ease-out;
  }

}

.Close {
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}

.unLogged.Close {
  height: 0;
  //z-index: -1;
}