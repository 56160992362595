.PersonalResidence-root {
  input[type="checkbox"] {
    transform: scale(1.5);
    flex: 0 1 5%;

  }

  &:before {
    display: none !important;

  }

  input {
    width: 15%;
    height: 30px;
    margin-right: 15px;
    cursor: pointer;
    border-radius: 4px;



    &:hover {
      &:before {
        display: none !important;
      }
    }
  }

  .otherIncome {
    position: relative;
    top: 4px;
    left: 4px;
  }

  .input-wrap-disabled {
    input[type="text"] {
      pointer-events: none;

      /* for "disabled" effect */
      //opacity: 0.4;
      background: rgba(236, 236, 236, .4);
    }
  }

}

