.Status-root {
  display: flex;
  //background: linear-gradient(90deg, #1999D4 0%, #1AC6D4 100%);
  border-radius: 4px;
  padding: 10px 15px;
  margin-left: 20px;

  @media (max-width: 992px) {
    height: 50px;
    margin-top: 30px;
  }
  @media (max-width: 585px) {
    height: 40px;
    margin-top: 0px;
    display: inline-block;
  }

  .status-icon {
    display: block;
    margin-right: 10px;

    @media (max-width: 1480px) {
      height: 25px;
    }

    @media (max-width: 1240px) {
      margin-right: 0;
    }

    @media (max-width: 992px) {
      margin-right: 10px;
      height: 5px;
    }

    @media (max-width: 767px) {
      margin-right: 0;
    }

    @media (max-width: 585px) {
      img {
        height: 34px;
        position: relative;
        top: -5px;
        left: 2px;
      }
    }
  }

  .status-text {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    vertical-align: center;
    padding-top: 5px;

    @media (max-width: 1480px) {
      font-size: 13px;
    }

    @media (max-width: 1240px) {
      display: none;
    }

    @media (max-width: 992px) {
      display: unset;
    }

    @media (max-width: 767px) {
      display: none;
    }

    //@media (max-width: 585px) {
    //  //display: unset;
    //  font-size: 13px;
    //  padding-top: 0;
    //}


  }
}

.statusBackground {
  background: linear-gradient(90deg, #1999D4 0%, #1AC6D4 100%);
}