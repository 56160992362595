.Servis {
  //margin-left: 25px;
  margin-top: 40px;
  padding-left: 15px;

  @media (max-width: 585px) {
    margin-top: 0;
    margin-bottom: 50px;
  }


  &-icon img {
    margin: auto;
    display: block;
    height: 25px;
    position: relative;
    top: 6px;
  }

  &-icon {
    width: 30px;
    margin-right: 15px;
  }

  .text-black {
    color: black;
  }

  .text-gray {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 14px;
    color: #5A7984;

  }

  &-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.05em;
    //text-transform: uppercase;
    color: #5A7984;
    margin-bottom: 5px;
  }

  .top-reset-a {
    top: 0;
  }

  a {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00B8F5;
    text-transform: none;
    position: relative;
    top: 5px;

    @media (max-width: 1180px) {
      font-size: 15px;
    }
  }
}