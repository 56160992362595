.LoanProcessStatus-root {
  background: #EB5757;
  border-radius: 4px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 900;
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;
  color: #FFFFFF;

  padding: 10px 15px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}