.Login-root {
  @media (min-width: 1480px) {
    //transform: translateY(30%);
  }
  @media (min-width: 1280px) {
    //transform: translateY(5%);
  }

  .login__wrapper {
    background: #fff;
    width: 50%;
    padding: 35px;
    border-radius: 15px;
    position: relative;
    top: 10%;
    min-height: 350px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 992px) {
      width: 80%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }

    @media (max-width: 580px) {
      padding: 25px 15px 15px 15px;
      min-height: 300px;

      .theoKeys {
          height: 120px;
          width: auto;
        }

    }
  }

  .loginHeading {
    text-align: center;
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: #B800F5;

    @media (max-width: 585px) {
      font-size: 22px;
    }
  }

  .tab__forgot_password {
    @media (max-width: 585px) {
      font-size: 14px;
    }
  }

  .remember-box {
    @media (max-width: 585px) {
      font-size: 14px;
    }
  }

  button {
    @media (max-width: 585px) {
      font-size: 12px;
    }
  }

  form {
    padding: 15px 0;
  }

  .tab {
    cursor: pointer;
    color: #00B8F5;
    text-decoration: underline;

    &:hover {
      color: #B800F5;
    }
  }

  .active {
    color: #B800F5;
    text-decoration: none;
  }

  .form-group {
    margin-bottom: 25px;

    input {
      height: 50px;
    }
  }

  button {
    margin-top: 35px;

    @media (max-width: 580px) {
      margin-top: 25px;
    }
  }
}