.nav {
  align-items: center;
  flex-wrap: unset;
  &-link {
    font-size: 16px;
  }

  @media (max-width: 992px) {
    display: block !important;
    flex-wrap: wrap;
  }
}

.header {
  position: relative;
  z-index: 15;
  //padding-top: 15px;
  margin-bottom: 20px;
}