.page {
  &__title {
    font-family: Quicksand;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    color: #000000;
    margin-bottom: 15px;

    @media (max-width: 1480px) {
      font-size: 30px;
    }

    @media (max-width: 480px) {
      text-align: center;
      font-size: 32px;
    }
  }

  &__section--title {
    font-family: Quicksand;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 35px;
    color: #000000;

    @media (max-width: 1480px) {
      font-size: 22px !important;
    }

  }
}

.purple-text {
  color: #B800F5;
}

.cursor-pointer {
  cursor: pointer;
  transition: all .1s linear;

  &:hover {
    color: #B800F5 !important;
  }
}

.text-underline {
  text-decoration: underline;
}

.cursor-detail {
  font-size: 11px;
}

.green-text {
  color: #28a745 !important;
}

.red-text {
  color: #dc3545 !important;
}

.orange-text {
  color: #F2994A !important;
}

.blue-text {
  color: #00b8f5 !important;
}

.text-gray-and-underline {
  font-size: 14px;
  color: #5A7984;
  text-decoration: underline;
  cursor: pointer;
  transition: all .2s linear;

  &:hover {
    color: #00b8f5;
  }
}

.heading-blue {
  font-family: Quicksand;
  font-weight: bold;
  font-style: normal;
  font-size: 24px;
  line-height: 40px;
  color: #00b8f5 !important;
}