.Purpose {
  &-purposeButtons {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    @media (max-width: 580px) {
      margin-top: 0;
    }
  }

  &-btn {
    position: relative;
    display: flex;
    //flex-wrap: wrap;
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
    width: 100%;
    margin: 10px 0;
    cursor: pointer;


    input[type=radio] {
      visibility: hidden;
    }

    &:before {
      display: block;
      content: '';
      height: 25px;
      width: 25px;
      border-radius: 50%;
      background: #FFFFFF;
      border: 1px solid #CCDFE5;

      @media (max-width: 585px) {
        height: 15px;
        width: 15px;
      }
      //@media (max-width: 580px) {
      //  height: 30px;
      //  width: 30px;
      //}
    }

    &__active:before {
      cursor: pointer;
      border:1px solid #B800F5;


    }

    &__active:after {
      cursor: pointer;
      position: absolute;
      height: 15px;
      width: 16px;
      content: '';
      left: 4.4px;
      top: 5px;
      border-radius: 50%;
      background: #B800F5;

      @media (max-width: 585px) {
        height: 9.2px;
        width: 9.2px;
        top: 3px;
        left: 3px;
      }

      //@media (max-width: 580px) {
      //  height: 10px;
      //  width: 10px;
      //  top: 10px;
      //  left: 10px;
      //}
    }

    .labelText {
      position: relative;
      top: -1px;
      cursor: pointer;
      //width: 100%;
      font-size: 16px;
      height: 30px;

      @media (max-width: 580px) {
        font-size: 16px;
        top: 4px;
      }
    }

    @media (max-width: 780px) {
      width: 100%;
    }
  }
}


.purpose-btn-wrap {
  @media (max-width: 585px) {
    flex-direction: column;
    justify-content: start !important;
    align-items: start;
    margin-top: -25px;
  }
}

.Radio {
  margin-top: 40px;
}