.Logo {
  .home-link {
    display: block;
  }

  img {
    width: 240px;
    height: 42px;
    transition: width .2s linear;
    position: relative;
    z-index: 5;

    @media (max-width: 1480px) {
      width: 205px;
      top: 5px;
    }

    @media (max-width: 1180px) {
      width: 150px;
    }

    @media (max-width: 768px) {
      width: 140px;
    }
  }

  @media (max-width: 992px) {
    //margin-bottom: 40px;
  }

  @media (max-width: 580px) {
    margin-bottom: 0;
  }
}