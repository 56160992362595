.PaymentOptions-root {

  .payment-wrap {
    padding: 15px;

    .payment-card,
    .payment-gate,
    .payment-qr,
    .payment-other{
      padding: 20px;
      transition: all .2s ease;
      cursor: pointer;
      border-radius: 4px;
      margin-right: 10px;
      width: 200px;
      height: 140px;

      @media (max-width: 585px) {
        border: 1px solid rgba(black, .1);
        border-radius: 15px;
        margin-bottom: 20px;
        margin-right: 0 !important;
      }

      &:last-child {
        margin-right: 0;
      }

      p {
        margin-bottom: 0;
        margin-top: 10px;
        font-family: Quicksand;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        text-align: center;

        /* modrá */

        color: #00B8F5;
      }
    }

    img {
      display: block;
      margin: auto;
    }

    .card-white,
    .gate-white,
    .qr-white,
    .other-white{
      display: none;
    }
    .card-blue,
    .gate-blue,
    .qr-blue,
    .other-blue {
      display: block;
    }

    .separator {
      padding: 25px 10px;

      @media (max-width: 585px) {
        display: none;
      }
    }

    .payment-card:hover {
      background: #00B8F5;
      .card-blue {
        display: none;
      }

      .card-white {
        display: block;
      }

      p {
        color: white;
      }
    }

    .payment-gate:hover {
      background: #00B8F5;
      .gate-blue {
        display: none;
      }

      .gate-white {
        display: block;
      }
      p {
        color: white;
      }
    }

    .payment-qr:hover {
      background: #00B8F5;
      .qr-blue {
        display: none;
      }

      .qr-white {
        display: block;
      }
      p {
        color: white;
      }
    }

    .payment-other:hover {
      background: #00B8F5;
      .other-blue {
        display: none;
      }

      .other-white {
        display: block;
      }
      p {
        color: white;
      }
    }

  }

  .SideNavigation {
    width: 95%;
    display: block;
    margin: 0 auto 20px auto;
  }

  .SideNavigation__list {
    width: 100% !important;
    margin-bottom: 0;
    border-radius: 10px;

    &-item {
      color: #00B8F5;
    }
  }

  .SideNavigation__sub-menu {
    padding-left: 0;

    @media (max-width: 580px) {
      flex-direction: column;
    }
  }

  .gray,
  .gray-blue {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    color: #5A7984;
    width: 90%;
    margin-top: 10px;
    margin-bottom: 0;
  }

  .gray-blue {
    color: #00B8F5;
  }

  .bankAccount__info {
    max-width: 50%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 25px;
  }

  .bankAccount__number,
  .bankAccount__amount,
  .bankAccount__VS {
    @media (max-width: 680px) {
      flex-direction: column;
      justify-content: center !important;
      text-align: center;
    }
  }

  .qrPayment__info {
    text-align: center;
    margin-top: 25px;

    .qrPayment__img {
      margin: 20px 0;

      img {
        height: 150px;
        width: 150px;
      }
    }

    h3 {
      @media (max-width: 580px) {
        font-size: 1.35rem;
      }
    }

    .btn-purple {
      @media (max-width: 992px) {
        top: 0 !important;
      }
    }
  }

  .paymentOption {
    margin-right: 15px;
    max-width: 120px;

    @media (max-width: 580px) {
      margin: auto;
    }



    .rectangle {
      padding: 3px;
      height: 120px;
      width: 120px;
      margin-top: 15px;
      border: 1px solid #CCDFE5;
      position: relative;

      img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &:hover {
      .rectangle {
        border: 1px solid #00B8F5
      }
    }

    .paymentOption__name {
      padding: 5px;
      display: block;
      margin-bottom: 0;
      text-align: center;
    }
  }
}

.bankAccount__info {
  max-width: 60%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}