.drop-shadow {
  filter: drop-shadow(0 0 .75rem rgba(0, 0, 0, 0.15));
}

.cursor-pointer {
  cursor: pointer
}

.cursor-link {
  color: #00b8f5;
  &:hover {
    text-decoration: underline;
  }
}

.max-width-70 {
  max-width: 70%;
}


.responsiveTable {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  @media (max-width: 765px) {
    //display: block;

  }

}


.status_green {background-color: #28a745 !important;}
.status_red {background-color: red !important;}
.status_orange {background-color: orange !important;}
.status_blue {background-color: #00B8F5 !important;}
.status_gray {background-color: gray !important;}
.status_white {background-color: white !important; color:  black !important;}

.cbx {
  -webkit-perspective: 20;
  perspective: 20;
  //position: absolute;
  //top: 50%;
  //left: 50%;
  margin: -12px 0;
  border: 2px solid #E8E8EB;
  background: linear-gradient(90deg, #B800F5 0%, #7D00A6 100%);
  border-radius: 4px;
  transform: translate3d(0,0,0);
  cursor: pointer;
  transition: all .3s ease;
  &:hover {
    border-color: #7D00A6;
  }
}

.flip {
  display: block;
  transition: all .4s ease;
  transform-style: preserve-3d;
  position: relative;
  width: 20px;
  height: 20px;
}

#cbx {
  display: none;
  &:checked + .cbx {
    border-color: #7D00A6;
    .flip {
      transform: rotateY(180deg)
    }
  }

}

.front,
.back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 2px;
}

.front {
  background: white;
  z-index: 1;
}

.back {
  transform: rotateY(180deg);
  background: linear-gradient(90deg, #B800F5 0%, #7D00A6 100%);
  text-align: center;
  color: white;
  line-height: 20px;
  //box-shadow: 0 0 0 1px #7D00A6;
  svg {
    margin-top: -5px;
    fill: none;
    path {
      stroke: white;
      stroke-width: 2.5;
      stroke-linecap: round;
      stroke-linejoin: round;

    }
  }
}

.max-width-100 {
  max-width: 100% !important;
}


.t-heading {
  font-weight: 700;
  line-height: 20.08px;
  //color: #5A7984;
}

.border-spacing {
  border-collapse: separate;
  border-spacing: 0 20px;
}


.prepare-sticky-content {
  min-height: 100%;
  position: relative;
  /* Equal to height of footer */
  /* But also accounting for potential margin-bottom of last child */
  margin-bottom: -50px;
}

.sticky-content {
  height: 50px;
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 585px) {
    bottom: 130px;
    position: fixed;
  }
}


.tabs {
  display: flex;
  flex-wrap: wrap; // make sure it wraps
  font-family: Mulish !important;
}
.tabs label {
  order: 1; // Put the labels first
  display: block;
  padding: 1rem 2rem;
  margin-right: 0.2rem;
  cursor: pointer;
  position: relative;
  z-index: 5;
  //background: #90CAF9;
  font-weight: bold;
  //border: 1px solid #CCDFE5;
  transition: background ease 0.2s;
  border-radius: 10px;
  border-bottom-color: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  @media (max-width: 585px) {
    padding: 15px 10px;
    width: 130px !important;
    text-align: center;
  }

}

.tabs .active-tab {
  background: #fff;
  border: 1px solid #CCDFE5;
  border-bottom-color: transparent;
}

.no-border {
  border-top-left-radius: 0 !important;
}

.no-border-top-right {
  //@media (max-width: 380px) {
  //  border-top-right-radius: 0 !important;
  //}
}

.tabs .tab {
  order: 99; // Put the tabs last
  flex-grow: 1;
  width: 100%;
  //display: none;
  padding:  50px 25px 30px 25px;
  background: #fff;
  margin-top: -10px;
  border: 1px solid #CCDFE5;
  border-radius: 10px;

}
.tabs input[type="radio"] {
  display: none;
}
.tabs input[type="radio"]:checked + label {
  background: #fff;
}
.tabs input[type="radio"]:checked + label + .tab {
  display: block;
}

@media (max-width: 45em) {
  .tabs .tab,
  .tabs label {
    order: initial;
  }
  .tabs label {
    width: 100%;
    margin-right: 0;
    margin-top: 0.2rem;
  }
}

.fz-small {
  //font-size: 14px;
}

.purple {
  color: #B800F5 !important;
}

.blue {
  color: #00B8F5 !important;

  a {
    color: #00B8F5 !important;

  }
}

.scrollBar-hidden {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.notification-text {
  @media (max-width: 580px) {
    font-size: 12px;
  }
}

.center-button {
  @media (max-width: 580px) {
    margin-left: auto;
    margin-right: auto;
    top: 0;
  }
}

.reset-button-top {
  top: 0 !important;
}

.height-80 {
  height: 80%;
}

.onlyDesktop-1024 {
  @media (max-width: 1024px) {
    display: none;
  }
}

.avatar {
  vertical-align: middle;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.selected-amount {
  border: 1px solid #00B8F5;
}
.BulletWrap {
  font-size: 15px;
  max-width: 50%;

  @media (max-width: 585px) {
      max-width: 100%;
  }
}


@-webkit-keyframes gradient {
  //from, to {
  //  //background: linear-gradient(90deg, #1999D4 0%, #1AC6D4 100%);
  //  background: linear-gradient(90deg, orange 0%, red 100%);
  //
  //}
  0% {
    //background: linear-gradient(90deg, orange 0%, red 100%);
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient {
  //from, to {
  //  //background: linear-gradient(90deg, #1999D4 0%, #1AC6D4 100%);
  //  background: linear-gradient(90deg, orange 0%, red 100%);
  //
  //}
  0% {
    //background: linear-gradient(90deg, orange 0%, red 100%);
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.borderBlink {
  //-webkit-animation: gradient 15s ease infinite ;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}


.document-pdf {
  .react-pdf__Page__svg,
  .react-pdf__Page {
    width: 100% !important;
    height: auto !important;
    overflow-x: hidden !important;
    overflow-y: auto;
    padding: 50px 0 10px 0;
  }

  svg {
    transform: scale(1.1);
    width: 100%;
    height: auto;
  }
}

.ModalContractDetail {
  height: 800px;
  overflow-y: hidden;

  .contractSteps {
    margin-top: -35px;
  }



  &--body {
    overflow-y: hidden;
    width: 100%;
    padding: 5px 15px 0 15px;
    height: 550px;
  }
}

.contract-paragraph {
  top: -5px !important;
  position: relative;
}

.contract-body-wrap-allSigned {
  height: 140px;
  align-items: center;
  justify-content: center;
  //display: flex;
  vertical-align: center;
}

.Toastify__toast {
  //color: #fff !important;
  //background: #48595F !important;
  top: 65px !important;
  box-shadow: 0px 25px 60px rgba(0, 84, 112, 0.15) !important;
  border-radius: 10px !important;

  @media (max-width: 992px) {
    top: 0 !important;
  }

  .Toastify__toast-body {
    //fill: #fff !important;
    font-size: 14px !important;
  }

  .Toastify__close-button > svg {
      //fill: #fff !important;
  }

  .Toastify__progress-bar--info {
    background: #FDC93A !important;
  }

  .Toastify__progress-bar--success {
    background: #6FCF97 !important;
  }

  .Toastify__progress-bar--error {
    background: #F67070 !important;
  }

  .Toastify__toast-icon {
    margin-right: 10px !important;
    width: 40px !important;
      //fill: #fff !important;
    img {
      height: auto !important;
      width: 100% !important;
    }
  }
}

.event-pointers-none {
  pointer-events: none;
}

.opacity-50 {
  opacity: .5;
}

.myTooltipClass {
  font-family: Mulish !important;

  .introjs-tooltip-title,
  .introjs-tooltiptext,
  .introjs-tooltip-header, {
    font-family: Mulish !important;
  }

}


.chartWrapLoanSum {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -45%);

  @media (max-width: 992px) {
    top: 40%;
  }
}


