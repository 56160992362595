.PersonalActivity-root {
  input[type="checkbox"] {
    transform: scale(1.5);
    flex: 0 1 5%;

  }

  &:before {
    display: none !important;

  }

  input {
    width: 15%;
    height: 30px;
    margin-right: 15px;
    cursor: pointer;
    border-radius: 4px;



    &:hover {
      &:before {
        display: none !important;
      }
    }
  }

  .otherIncome {
    position: relative;
    top: 4px;
    left: -4px;
  }

  .arrow-wrap {
    position: relative;
    justify-content: flex-end;
    .input-wrap {
      width: 85% !important;
    }
  }

  .arrowField {
    position: absolute;
    top: -15px;
    left: 10px;
    z-index: 0;
  }
}

.arrow-blue-input {
  position: relative;
  padding-left: 30px;

  &::before {
    content: "";
    background: url("../../../../assets/icons/blue-arrow.svg") no-repeat;
    background-size: contain;
    height: 50px;
    width: 30px;
    left: 7px;
    top: -10px;
    z-index: 1;
    position: absolute;
  }
}