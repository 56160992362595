.btn-main {
  padding: 16px 32px;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
  border: 1px solid #CCDFE5;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  margin: auto 10px;
  transition: all .2s ease-in-out;

  &:hover {
    box-shadow: 0px 10px 35px rgba(158, 158, 158, 0.28);

  }

  @media (max-width: 1180px) {
    padding: 19px 22px;
  }

  @media (max-width: 992px) {
    margin-bottom: 15px;
    display: block;
  }
}