.card {
  border: 1px solid #CCDFE5;
  border-radius: 10px;
  background: #fff;
  padding: 20px;
  transition: all .2s linear;
  //margin-bottom: 15px;
  position: relative;
  z-index: 4;


  @media (max-width: 1280px) {
    width: 100%;
  }

  &__body {
    .table {
      td {
       border: none;
      }
    }
  }


  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &--wrap {
      @media (max-width: 480px) {
        flex-direction: column;
      }
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    transition: all .2s linear;

    &--wrap {
      @media (max-width: 600px) {
        flex-direction: column;
      }
    }

    @media (max-width: 480px) {
      margin-top: 0;
    }
  }

  &__label {
    display: block;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #5A7984;
    margin-bottom: 5px;
  }

  &__loan-type {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
  }

  &__name {
    @media (max-width: 480px) {
      order: 2;
    }
  }

  &__ID {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #5A7984;
  }

  &__process--status {
    @media (max-width: 480px) {
      order: 1;
      margin-bottom: 10px;
    }
  }

  &__item {
    //display: block;
    //min-width: 150px;
    //max-width: 200px;
    //margin-right: 40px;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    transition: all .2s linear;

    //@media (max-width: 1540px) {
    //  //margin-right: 10px;
    //  //min-width: 120px;
    //}

    //@media (max-width: 1340px) {
    //  //min-width: 105px;
    //}
    //
    //@media (max-width: 1250px) {
    //  //min-width: 150px;
    //}
    //
    //@media (max-width: 992px) {
    //  min-width: 120px;
    //  margin-top: 10px;
    //}
  }

  &__process-status {
    background: #EB5757;
    border-radius: 4px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 900;
    font-size: 11px;
    line-height: 14px;
    text-transform: uppercase;
    color: #FFFFFF;

    padding: 10px 15px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }


  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    .btn-main,
    .btn-purple {
      margin: 0;
    }

    .btn-purple {
      @media (max-width: 992px) {
        top: 0;
        margin-top: 15px;
      }
    }

    .btn-main {
      @media (max-width: 992px) {
        padding: 16px 32px;
        font-size: 13px;
        height: 100%;
        position: relative;
        //top: 15px;
      }

      @media (max-width: 500px) {
        top: 0;
      }
    }

    @media (max-width: 500px) {
      flex-direction: column;
      justify-content: center;
    }
  }
}