.Modal {
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0px 50px 120px rgba(0, 84, 112, 0.25);
  border-radius: 10px;
  //top: 10%;
  width: 100%;
  top: 50%;
  left: 50%;
  z-index: -1;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 35px;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 585px) {
    transform: translate(0);
    top: 0;
    left: 0;
  }

  .notification {
    z-index: -1;
    position: relative;
  }

  .showNotification {
    height: 40px;
    margin-top: -40px;
    margin-bottom: 25px;
    transition: all .2s linear;
    z-index: 2;
  }

  .text-small {
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    /* or 156% */
    text-align: center;

    /* šedá */

    color: #5A7984;
    margin-top: 25px;
    b {
      color: #000;
    }


  }

  &-heading {
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 36px;
    /* or 143% */

    text-align: center;

    /* černá */

    color: #000000;

    @media (max-width: 580px) {
      font-size: 18px;
      line-height: 30px;
    }
  }


  &-text {
    // font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    /* identical to box height, or 160% */

    text-align: center;

    /* šedá */

    color: #5A7984;

    margin-top: 45px;

    @media (max-width: 580px) {
      margin-top: 25px;
      font-size: 16px;
      line-height: 28px;
    }
  }

  &-phone {
    // font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    /* identical to box height, or 100% */

    text-align: center;

    /* černá */

    color: #000000;
    transition: all .2s linear;

    &:hover {
      color: #00B8F5;
    }

    @media (max-width: 580px) {
      font-size: 24px;
      line-height: 26px;
    }
  }

  &-email {
    //font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    /* identical to box height, or 100% */

    text-align: center;
    text-decoration-line: underline;

    /* modrá */

    color: #00B8F5;
    transition: all .2s linear;

    &:hover {
      color: #00B8F5;
    }

    @media (max-width: 580px) {
      font-size: 24px;
      line-height: 26px;
    }
  }

  &-btn {
    // font-family: Muli;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    text-transform: uppercase;

    /* bílá */

    color: #FFFFFF;
  }

  @media (min-width: 800px) {
    position: fixed;
    width: 800px !important;
    //top: 20% !important;
    //left: 50%;
    //transform: translateX(-50%) !important;
    //padding: 65px;
    text-align: center;
  }

  @media (max-width: 480px) {
    padding: 20px;
  }



  .Modal-text {
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 36px;
    /* or 143% */

    text-align: center;

    /* černá */

    color: #000000;

    @media (max-width: 580px) {
      font-size: 18px;
      line-height: 30px;
    }

    a {
      font-size: 22px;
      line-height: 36px;
      @media (max-width: 580px) {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }
  .text-purple {
    color: #B800F5 !important;
  }
}
.showModal {
  transform: translate(-50%, -50%);
  opacity: 1;
  z-index: 1500;

  @media (max-width: 585px) {
    transform: translate(0%, 0%);
    top: 0;
    left: 0;
  }
}


#text-purple {
  color: #B800F5 !important;
}

.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background: #FFFFFF;
  opacity: 0.85;
  filter: blur(4px);
}

.closeModal {
  display: block;
  margin-left: auto;
  cursor: pointer;
}


.modal__header {
  margin-bottom: 25px;
}

.ModalLoanDetail {
  overflow-y: auto;
  width: 90% !important;

  @media (min-width: 800px) {
    width: 90% !important;
    height: 700px;
  }

  @media (max-width: 585px) {
    position: fixed;
    overflow-y: scroll;
    height: 100%;
    width: 100% !important;
  }
}

.ModalFull {


  @media (max-width: 585px) {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    overflow-y: auto;

    .modal__body {
      margin-top: 50px;
    }

    .page__section-title {
      text-align: center !important;
      font-size: 22px;
    }

    .mt-mob {
      margin-top: 15px;
    }
  }

}

.modalSizeSmall {
  height: 400px;
}

.ModalLoanDetailShrink {
  max-width: 60%;

  @media (max-width: 1280px) {
    max-width: 85%;
  }


  @media (max-width: 585px) {
    max-width: 100%;
  }
}