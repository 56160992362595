.ClientStatus {
  display: block;
  border-radius: 4px;
  padding: 6px 20px;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
  border: 1px solid #CCDFE5;
  margin-right: 10px;

  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;

  .text-blue {
    font-size: 12px;
    line-height: 15px;
  }

  @media (max-width: 992px) {
    border: none;
    margin-top: 30px;
    padding-left: 15px;
  }
}