@import "purpleButton";
@import "whiteButton";

.top-0 {
  top: 0 !important;
}

.btn-purple {
  max-width: 250px;
}

.btn-red {
  padding: 19px 20px;
  color: #EB5757;
  border: 1px solid #EB5757;
}