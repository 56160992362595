html,
body,
#root,
.App {
  height: 100%;
  font-family: Mulish !important;
}

body {
  background-color: #FAFCFD;

}
