.Table {
  background-color: transparent;
  font-family: Mulish;
  //min-height: 600px;

  thead {
    font-size: 12px;
    th {
      border-top: none;
      border-bottom: 1px solid #dee2e6;
    }
  }

  tbody {
    background-color: #fff;
    font-weight: 400;
    font-size: 15px;
    line-height: 18.83px;

    td {
      border-color: #CCDFE5;
      border-top: none;
      border-bottom: 1px solid #CCDFE5;
      padding: 20px 15px;
    }
  }

  .url {
    color: #00B8F5;
    text-decoration: underline;
    position: relative;
    vertical-align: center;
    align-self: center;
    margin-left: 10px;
    top: 3px;
  }
}

.LoanHistoryTableItemMobile {
  padding: 10px 15px;
  background-color: rgba(255, 255, 255, .94);
  border-radius: 10px;
  //border: 1px solid #CCDFE5;
  margin-bottom: 10px;
  min-height: 70px;
  box-shadow: 0px 2px 10px rgba(0,0,0, .12);
}

.tableBodyRestriction {
  max-height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;
}

