.LoanDetailHeader {

}

.loanNumber {
  color: #00b8f5;
  border-radius: 5px;
  text-align: left;
}

.loanType-marketing {
  font-size: 12px;
  line-height: 16px;
  color: #B800F5;
  background: #FBEFFF;
  border-radius: 4px;
  padding: 10px;

  @media (max-width: 585px) {
    position: relative;
    //top: 50%;
    //transform: translateY(-50%);
  }
}

.LoanDetail-root {
  border-top: 1px solid #CCDFE5;
  padding-top: 10px;

  .LoanDetail__menu {
    display: flex;
    border-bottom: 1px solid #CCDFE5;


    &-item {
      padding: 10px;
      width: 33.33%;
      //border-bottom: 1px solid #CCDFE5;
      cursor: pointer;

      h2 {
        font-family: Quicksand;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #00B8F5;
        text-align: center;
      }
    }

    .LoanDetail__active-item {
      border-bottom: 2px solid #00B8F5;
    }
  }
}

.detailNavWrapper {
  position: absolute;
  top: 25px;
  left: 32px;
}

.tabName {
  position: relative;
  top: 8px;
  margin-left: 8px;

  @media (max-width: 992px) {
    left: 40px;
  }
}

.active-tab {
  color: #00b8f5;
  border-bottom: 1px solid #00b8f5;
}


.navLoanDetail {
  @media (max-width: 992px) {
    width: 100vw;
    left: 0;
    top: 20px;
    position: fixed;
    height: 100%;
    max-height: 200px;
    z-index: 100;


    .DrawerTogglerDetail {
      position: absolute;
      left: 5px;
    }
  }
}

.LoanDetailNavigation {
  transition: all .2s linear;
  font-size: 0;
  z-index: 105;


  .cursor-pointer {
    @media (min-width: 992px) {
      font-size: 18px;
      margin-right: 25px !important;
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;
    background: white;
    height: 100% !important;
    position: relative;
    width: 100vw;
    box-shadow: -3px 12px 8px rgba(0,0,0, .2);
    justify-content: space-around !important;
  }
}

.showDetailNav {
  height: 42px;
  visibility: visible;
  font-size: 16px;
  margin-bottom: 40px;

}

.hideDetailNav {
  height: 0;
  visibility: hidden;
}

.chartWrap {
  max-width: 350px;
  height: auto;
  margin: 50px auto 0 auto;
  position: relative;

  @media (max-width: 992px) {
    max-width: 250px;
  }
}

.LoanHistoryTableMobile {
  overflow-y: scroll;
  height: 65vh;
}

.LoanDetail {
  transition: all .2s linear;
}

.showNavMargin {
  margin-top: 100px !important;
}

.mobile-table {
  padding: 15px 0 50px 0;
}