.ActiveLoan {
  display: flex;
  flex-direction: row;
  transition: all .3s linear;

  @media (max-width: 1280px) {
    flex-direction: column;
  }

  padding-left: 5px;

  @media (max-width: 992px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 0;
  }

  .ActiveLoan__wrap {
    width: 55%;
    transition: all .3s linear;
    margin-bottom: 20px;

    @media (max-width: 1900px) {
      width: 70%;
    }

    @media (max-width: 1480px) {
      width: 55%;
    }

    @media (max-width:1280px) {
      width: 100%;
    }
  }

  .d-block-wrapper {
    @media (max-width: 992px) {
      width: 100%;
    }
  }
}

.LoanCard {
  border: 1px solid #CCDFE5;
  border-radius: 10px;
  background: #fff;
  padding: 20px;
  transition: all .2s linear;
  //margin-bottom: 15px;
  position: relative;
  z-index: 4;


  @media (max-width: 1280px) {
    width: 100%;
  }

}

.LoanCard,
.DeferredConfirm-root {
  .LoanCard__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .wrap {
      @media (max-width: 480px) {
        flex-direction: column;
      }
    }
  }

  .LoanCard__row {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    transition: all .2s linear;

    .wrap {
      @media (max-width: 600px) {
        flex-direction: column;
      }
    }

    @media (max-width: 480px) {
      margin-top: 0;
    }
  }

  .LoanCard__label {
    display: block;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #5A7984;
    margin-bottom: 5px;
  }

  .LoanCard__loan-name {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
  }

  .LoanCard__ID {
    .ID {
      font-family: Mulish;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: right;
      color: #5A7984;
    }
  }

  .LoanCard__name {
    @media (max-width: 480px) {
      order: 2;
    }
  }

  .LoanCard__process-status {
    @media (max-width: 480px) {
      order: 1;
      margin-bottom: 10px;
    }
  }

  .LoanCard__item {
    display: block;
    min-width: 150px;
    max-width: 200px;
    margin-right: 40px;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    transition: all .2s linear;

    @media (max-width: 1540px) {
      margin-right: 10px;
      min-width: 120px;
    }

    @media (max-width: 1340px) {
      min-width: 105px;
    }

    @media (max-width: 1250px) {
      min-width: 150px;
    }

    @media (max-width: 992px) {
      min-width: 120px;
      margin-top: 10px;
    }
  }

  .LoanCard__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    .btn-main,
    .btn-purple {
      margin: 0;
    }

    .btn-purple {
      @media (max-width: 992px) {
        top: 0;
        margin-top: 15px;
      }
    }

    .btn-main {
      @media (max-width: 992px) {
        padding: 16px 32px;
        font-size: 13px;
        height: 100%;
        position: relative;
        //top: 15px;
      }

      @media (max-width: 500px) {
        top: 0;
      }
    }

    @media (max-width: 500px) {
      flex-direction: column;
      justify-content: center;
    }
  }
}

.loan__wrap-mobile {
  @media (max-width: 585px) {
    width: 100%;
  }
}

.DeferredConfirm-root {
  position: relative;
  padding-left: 30px;

  .page__section-title {
    font-size: 22px;
    line-height: 26px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 5px;
    top: -20px;
    height: 60px;
    width: 30px;
    background-image: url("../../../icons/bluArrowDashed.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
}