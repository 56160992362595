.LoanPayment-root {
  border: 1px solid #CCDFE5;
  border-radius: 10px;
  background: #fff;
  transition: all .2s linear;
  width: 95%;
  margin-left: auto;
  position: relative;
  margin-top: 20px;
  animation: fadeDown .2s linear;

  @media (max-width: 1250px) {
    margin-bottom: 15px;
  }

  @media (max-width: 580px) {
    width: 90%;
  }

  &:before {
    content: "";
    background: url("../../../../assets/icons/blue-arrow.svg") no-repeat;
    background-size: contain;
    height: 70px;
    width: 30px;
    left: -30px;
    top: -26px;
    z-index: 1;
    position: absolute;

    @media (max-width: 1480px) {
      left: -25px;
    }

    @media (max-width: 580px) {
      left: -25px;
    }
  }
}


.Payment-root {
  background: #FAFCFD;
  padding: 40px 20px;
  border-radius: 10px;

  .w-95 {
    width: 95% !important;
  }

  .PaymentBox__wrap {
    width: 95%;
    margin: auto;
    display: block;
    border: 1px solid #CCDFE5;
    border-radius: 4px;
    background: #fff;
    padding: 15px;
    transition: all .2s linear;
    position: relative;

    @media (max-width: 1480px) {
      width: 95%;
    }

    .inputElement {
      margin-bottom: 0;
      min-width: 250px;
      width: 100%;

      @media (max-width: 580px) {
        min-width: unset;
      }
    }

    .input__wrap {
      @media (max-width: 1480px) {
        max-width: 250px;
        min-width: unset;
        width: 250px
      }

      @media (max-width: 580px) {
        width: 100%;
      }
    }

    .currency {
      position: relative;
      top: 7px;
      margin-left: 5px;
    }

    .inputError {
      border: 1px solid red;

      &:active,
      &:focus {
        outline-color: red !important;
        border: 2px solid red  !important;
      }
    }

    .errorPayment {
      color: red;
      margin-top: 4px;
      max-width: 300px;
    }

    @media (max-width: 680px) {
      flex-direction: column;
    }

    .PaymentBox__amount {
      display: block !important;

      .LoanCard__label {
        font-family: Mulish;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #5A7984;
        margin-bottom: 10px;
      }

      span {
        display: block;
        font-family: Mulish;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #000000;

        @media (max-width: 680px) {
          text-align: center;
        }
      }
    }

    .btn-purple {
      margin: unset;
      max-height: 50px;
      margin-top: auto;
      margin-bottom: auto;
      top: 0;


      @media (max-width: 992px) {
        top: 0;
      }

      @media (max-width: 680px) {
        max-width: 200px;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}