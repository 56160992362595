.SideNavigation {
  width: 260px;

  @media (max-width: 1480px) {
    width: 240px ;
  }

  @media (max-width: 992px) {
    width: 100%;
  }

  @media (max-width: 992px) {
    margin-top: 15px;
  }

  @media (max-width: 585px) {
    margin-top: 0;
  }

  &__list {
    list-style: none;
    padding-left: 0;
    border: 1px solid #CCDFE5;
    background: #fff;

    .active {
      font-weight: bold;
    }
  }

  &__list-item {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    border-bottom: 1px solid #CCDFE5;
    padding: 20px 15px;
    cursor: pointer;

    @media (max-width: 1480px) {
      font-size: 15.5px;
    }

    &:last-child {
      border-bottom: none;
    }

    a {
      color: #000;
      transition: all .1s linear;

      span {
        color: #5A7984;
        font-weight: normal;
      }

      &:hover {
        color: #00B8F5 !important;

      }
    }

    .hoverItem {
      &:hover {
        color: #00B8F5 !important;
        text-decoration: none;
      }
    }

    .loanStatus {
      font-size: 12px;
      line-height: 16px;
      color: #B800F5;
      background: #FBEFFF;
      border-radius: 4px;
      padding: 10px;
    }
  }

  &__top-item {
    span {
      color: #6c757d !important;
      font-size: 11px;
    }
  }

  &__sub-menu {
    height: 0;
    padding-left: 25px;
    overflow: hidden;
    font-size: 14px;
    line-height: 22px;
    list-style: none;

    li {
      padding: 5px 0;
      cursor: pointer;

      span {
        color: #5A7984;
        font-weight: normal;
        font-size: 12px;
      }

      &:hover {
        color: #00B8F5;
        text-decoration: underline;
      }
    }
  }

  .arrowdrop {
    transition: all .1s linear;
  }

  .open {
    .SideNavigation__sub-menu {
      height: auto;
      overflow: unset;
    }

    .arrowdrop {
      transform: rotate(180deg);
    }
  }
}

.grayBackground {
  background: #F2F6F8;
}