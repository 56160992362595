.Status {
  background-color: #27AE60;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  border-radius: 4px;
  padding: 10px;
  text-transform: uppercase;
  font-weight: bold;
  max-height: 40px;
}
