.wrapper {
  display: flex;
  flex-direction: row;
  transition: all .3s linear;

  @media (max-width: 1280px) {
    flex-direction: column;
  }

  padding-left: 5px;

  @media (max-width: 992px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 0;
  }

  &__child {
    width: 55%;
    transition: all .3s linear;
    margin-bottom: 20px;

    @media (max-width: 1900px) {
      width: 70%;
    }

    @media (max-width: 1480px) {
      width: 55%;
    }

    @media (max-width:1280px) {
      width: 100%;
    }
  }
}