.HintBox-root {
  border: 1px solid #CCDFE5;
  border-radius: 10px;
  background: #fff;
  padding: 20px;
  //margin-left: 25px;
  //height: 100%;
  max-width: 450px;
  min-width: 400px;
  transition: all .2s linear;


  @media (max-width: 1280px) {
    margin-left: 0;
    margin-top: 20px;
  }

  @media (max-width: 580px) {
    min-width: 100%;
  }

  .HintBox__title {
    font-family: Quicksand;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 34px;
    color: #000000;
  }

  .HintBox__paragraph {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    color: #5A7984;
    width: 70%;
    margin-top: 15px;
  }

  .HintBox__direction {
    position: relative;
    margin-top: -40px;
    display: flex;
    height: 142px;
    transition: all .2s linear;


    img {
      position: absolute;
      bottom: -20px;
      right: -20px;
      height: 160px;
      transition: all .2s linear;

      @media (max-width: 480px) {
        height: 130px;
      }
    }

    @media (max-width: 480px) {
      height: 130px;
    }
  }

  .HintBox__link {
    position: absolute;
    bottom: 15px;
    left: 0;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #00B8F5;

    @media (max-width: 480px) {
      bottom: 0px;
    }
  }

  .purple-text {
    color: #B800F5;
  }

}

.rentImage {
  position: absolute;
  bottom: 0;
  left: 20px;
  max-height: 180px;
}