.LoanDeferredPaymentCard-root {
  border: 1px solid #CCDFE5;
  border-radius: 10px;
  background: #fff;
  //padding: 20px;
  transition: all .2s linear;
  width: 95%;
  margin-left: auto;
  margin-top: 20px;
  position: relative;
  animation: fadeDown .2s linear;


  @media (max-width: 1250px) {
    margin-bottom: 15px;
  }

  @media (max-width: 580px) {
    width: 90%;
  }

  &:before {
    content: "";
    background: url("../../../../assets/icons/blue-arrow.svg") no-repeat;
    background-size: contain;
    height: 70px;
    width: 30px;
    left: -30px;
    top: -26px;
    z-index: 1;
    position: absolute;

    @media (max-width: 1480px) {
      left: -25px;
    }

    @media (max-width: 580px) {
      left: -25px;
    }
  }

  .LoanDeferredPaymentCard__wrap {
    padding: 20px;
  }

  .LoanDeferredCard__header {

    .LoanDeferredCard__heading {
      font-family: Quicksand;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: #000000;
    }


    }
  }

  .label {
    margin-top: -25px;

    @media (max-width: 585px) {
      margin-top: -10px;
    }

  .DeferredBtn {
    width: 100%;
    cursor: pointer;

    &:before {
      height: 30px;
      width: 30px;

      @media (max-width: 585px) {
        height: 15px;
        width: 15px;
      }

    }

    &:after {
      width: 15px;
      height: 15px;
      top: 7.4px;
      left: 7.8px;

      @media (max-width: 585px) {
        height: 9.2px;
        width: 9.2px;
        top: 3px;
        left: 3px;
      }
    }

    .price {

    }

    .d-flex {
      width: 90%;
      position: relative;
      top: -8px;

      @media (max-width: 1480px) {
        flex-direction: column;

      }

      @media (max-width: 580px) {
        width: 80%;
        height: auto;
        display: block !important;
      }
    }



    span {
      font-family: Mulish;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .Purpose-btn__active {
    span {
      color: #B800F5;
    }
  }
}

.DeferredButton-wrap {
  @media (max-width: 1480px) {
    justify-content: center;
  }

  @media (max-width: 680px) {
    flex-direction: column;
  }

  .btn-purple {
    //margin: unset;
    margin: auto;
    @media (max-width: 1480px) {
      max-height: 55px;
    }

    @media (max-width: 992px) {
      top: 0;
    }

    @media (max-width: 680px) {
      max-width: 200px;
      margin: auto;
    }


  }

  .loanSummary-info {
    width: 70%;
    margin-left: 0;

    @media (max-width: 1480px) {
      width: 50%;

      p {
        font-size: 12px;
      }
    }

    @media (max-width: 680px) {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

@keyframes fadeDown {
  0% {
    top: -100px;
  }
  100% {
    top: 0;
  }
}

.LoanDeferredPaymentModal {
  .btn-purple {
    @media (max-width: 585px) {
      //max-width: 200px;
      display: block !important;
      margin-left: auto !important;
      margin-right: auto !important;
      top: 0;
      margin-top: 20px;
    }
  }
}