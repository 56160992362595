.header {
  position: relative;
  @media(max-width: 992px) {
    z-index: 15;
  }

  @media (max-width: 580px) {
    margin-bottom: 20px;
  }

  .DesktopOnly {
    @media (max-width: 992px) {
      display: none !important;
    }
  }
}